/* eslint-disable react/jsx-no-target-blank */
import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";

import { Button, Carousel } from "react-bootstrap-v5";
import { BASE_URL, GET_SLIDERSETTINGS } from "../../constant/constants";

const Hero = () => {
  const [contents, setContents] = useState([]);
  console.log("sliderssss", contents);

  //for content
  useEffect(() => {
    fetch(`${GET_SLIDERSETTINGS}`)
      .then((response) => response.json())
      .then((data) => {
        // setContents(data.homepage_sliders);
        console.log("sliderImages", data.homepage_sliders);
        setContents(data.homepage_sliders);
        // data.homepage_sliders.map((n) => setContents(n.image));
      })
      .catch(() => {});
  }, []);

  return (
    <Carousel fade>
      {contents.map((n) => (
        <Carousel.Item>
          <img
            className='d-block w-100 '
            // style={{ objectFit: "cover", opacity: "0.7" }}
            style={{
              filter: "brightness(100%)",
              paddingTop: "80px",
              height: window.innerWidth <= 767 ? "fit-content" : "600px",
            }}
            // height={600}
            src={`${BASE_URL}${n.image}`}
            alt='First slide'
          />

          <Carousel.Caption style={{ bottom: "5.25rem", marginTop: "30px" }}>
            <Interweave
              allowAttributes
              allowElements
              disableLineBreaks={false}
              content={n.title}
              className='mt-5'
            />
            <Interweave
              allowAttributes
              allowElements
              disableLineBreaks={false}
              content={n.subtitle}
            />
            <br />
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Hero;

//  <Button
//    style={{
//      fontWeight: "700",
//      color: "white",
//      fontSize: "large",
//      marginTop: "40px",
//      padding: "15px",
//      width: "200px",
//      border: "none",
//      backgroundColor: "rgb(255, 188, 19)",
//    }}
//  >
//    {/* {n.subtitle || "Our Portfolio"} */}
//    <a href={n.link} target='_blank'>
//      {n.details}
//    </a>
//  </Button>;
