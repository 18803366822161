import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../constant/constants";
import shape from "../../images/ab-shape-2.png";
import { Interweave } from "interweave";
import { useDispatch, useSelector } from "react-redux";
import { getAllcontents, getAllimages } from "../../dataSlice/dataSlice";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const AboutS2 = (props) => {
  // const { id } = useParams();
  console.log("aboutID", props.id);
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");
  console.log(`contentsImage`, contentsImage);
  const dispatch = useDispatch();
  const images = useSelector((state) => state.data.images);
  const menu_contents = useSelector((state) => state.data.contents);
  //for content images
  useEffect(() => {
    dispatch(getAllimages(props.id));
    setContentsImage(images);
    dispatch(getAllcontents(props.id));
    setContents(menu_contents);
  }, [dispatch, images, menu_contents, props.id]);

  const newContents = items(contents);
  console.log("content", newContents);

  return (
    <section className="wpo-about-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-wrap">
              {Object.entries(contentsImage ? contentsImage : {}).map(
                ([key, value]) =>
                  key === "About" ? (
                    <div className="wpo-about-img">
                      <img
                        style={{ height: "700px" }}
                        src={`${BASE_URL}/media/${value}`}
                        alt=""
                      />
                      <div className="wpo-ab-shape-1">
                        <div className="s-s1"></div>
                        <div className="s-s2"></div>
                      </div>
                      <div className="wpo-ab-shape-2">
                        <img src={shape} alt="" />
                      </div>
                    </div>
                  ) : (
                    " "
                  )
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12">
            <div className="wpo-about-text px-2">
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "About" ? (
                    <div className="" key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                        className="text-justify"
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutS2;
