import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import LifeMember from "../../components/LifeMember";

const LifeMemberPage = () => {
  const [profileId, setProfileId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === "Life Member" ? setProfileId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);
  console.log("Life Member", profileId);

  return (
    <Fragment>
      <PageTitle pageTitle={"Our Life Member"} pagesub={"Life Member"} />
      <LifeMember id={profileId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default LifeMemberPage;
