import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_CAUSES_WITHOUT_PAGINATION,
  GET_CONTENTS_BY_MENU_ID,
} from "../../constant/constants";
import { getAllcontents } from "../../dataSlice/dataSlice";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const CauseSection = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const menu_contents = useSelector((state) => state.data.contents);
  //for content
  useEffect(() => {
    dispatch(getAllcontents(id || props.id));
  }, [dispatch, id, props.id]);

  //for content items
  const newContents = items(menu_contents);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [causes, setCauses] = useState([]);

  //for content images
  useEffect(() => {
    fetch(`${GET_CAUSES_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setCauses(data.causes);
      })
      .catch(() => {});
  }, []);
  const Causes = [];

  // eslint-disable-next-line array-callback-return
  causes.map((cause) => {
    Causes.push({
      id: cause.id,
      cImg: cause.image,
      //   cImgSingle: cimgSingle,
      process: cause.process,
      cTitle: cause.name,
      Goal: cause.goal_amount,
      Raised: cause.raised_amount,
      authorImg: cause.created_by?.image,
      authorName: `${cause.created_by.first_name} ${cause.created_by.last_name}`,
    });
  });

  return (
    <div className={`wpo-campaign-area section-padding ${props.CmClass}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="wpo-section-title">
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Cause Head" ? (
                    <div className="" key={index}>
                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={value}
                      />
                    </div>
                  ) : (
                    " "
                  )
                )
              )}
            </div>
          </div>
        </div>
        <div className="wpo-campaign-wrap">
          <div className="row">
            {Causes.map((Cause, citem) => (
              <div className="col-lg-4 col-md-6 col-12" key={citem}>
                <div className="wpo-campaign-single">
                  <div className="wpo-campaign-item">
                    <div className="wpo-campaign-img">
                      <img
                        src={`${BASE_URL}${Cause.cImg}`}
                        alt=""
                        height="228"
                      />
                      {/* <span className="thumb">{Cause.thumb}</span> */}
                    </div>
                    <div className="wpo-campaign-content">
                      <div className="wpo-campaign-text-top">
                        <h2>
                          <Link
                            onClick={ClickHandler}
                            to={`/cause-single/${Cause.id}`}
                          >
                            {Cause.cTitle}
                          </Link>
                        </h2>
                        <div className="progress-section">
                          <div className="process">
                            <div className="progress">
                              <div
                                className="progress-bar"
                                style={{ width: `${Cause.process}%` }}
                              >
                                <div className="progress-value">
                                  <span>{Cause.process}</span>%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <span>Goal:</span> {Cause.Goal}
                          </li>
                          <li>
                            <span>Raised:</span> {Cause.Raised}
                          </li>
                        </ul>
                        <div className="campaign-btn">
                          <ul>
                            <li>
                              <span>
                                <img
                                  src={`${BASE_URL}${Cause.authorImg}`}
                                  alt=""
                                  width="45"
                                  height="45"
                                />
                              </span>
                              <span>
                                <Link
                                  onClick={ClickHandler}
                                  to={`/cause-single/${Cause.id}`}
                                >
                                  {Cause.authorName}
                                </Link>
                              </span>
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                className="e-btn"
                                to={`/donate/${Cause.id}/${Cause.cTitle}`}
                              >
                                Donate Now
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CauseSection;
