import React, { useEffect, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
} from "../../constant/constants";
import { Interweave } from "interweave";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const EventTabs = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [contentsImage, setContentsImage] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { id } = useParams();
  const [contents, setContents] = useState("");

  console.log("menuID", id);

  //for content images
  useEffect(() => {
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);
        console.log("imagesMessage", data.content_images);
        // Object.entries(data.content_images).map(([key, value]) =>
        //   key === "Profile" ? value.map((n) => setContentsImage(n)) : " "
        // );
      })
      .catch(() => {});
  }, [id, props.id]);

  //for get menu
  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("ContactContent", data);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content items
  const newContents = items(contents);
  console.log("content Contact", newContents);

  return (
    <div className="wpo-event-details-wrap">
      <div className="wpo-event-details-tab">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Managing Director" ? <>{key}</> : " "
                )
              )}
            </NavLink>
          </NavItem>
          <NavItem
            style={{
              display:
                BASE_URL === "https://api.activemanpowerservices.com/"
                  ? "none"
                  : "block",
            }}
          >
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Chairman" ? <>{key}</> : " "
                )
              )}
            </NavLink>
          </NavItem>
          <NavItem
            style={{
              display:
                BASE_URL === "https://api.activemanpowerservices.com/"
                  ? "none"
                  : "block",
            }}
          >
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              {newContents.map((service, index) =>
                Object.entries(service).map(([key, value]) =>
                  key === "Executive Director" ? <>{key}</> : " "
                )
              )}
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <div className="wpo-event-details-content">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <div id="Schedule" className="tab-pane active">
                  {Object.entries(contentsImage ? contentsImage : {}).map(
                    ([key, value]) =>
                      key === "Managing Director" ? (
                        <div className="">
                          <img
                            style={{ width: "100%", height: "350px" }}
                            src={`${BASE_URL}/media/${value}`}
                            alt=""
                          />
                        </div>
                      ) : (
                        " "
                      )
                  )}
                  {newContents.map((service, index) =>
                    Object.entries(service).map(([key, value]) =>
                      key === "Managing Director" ? (
                        <>
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={true}
                            content={value}
                          />
                        </>
                      ) : (
                        " "
                      )
                    )
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <div id="Schedule" className="tab-pane active">
                  {Object.entries(contentsImage ? contentsImage : {}).map(
                    ([key, value]) =>
                      key === "Chairman" ? (
                        <div className="">
                          <img
                            style={{ width: "100%", height: "350px" }}
                            src={`${BASE_URL}/media/${value}`}
                            alt=""
                          />
                        </div>
                      ) : (
                        " "
                      )
                  )}
                  {newContents.map((service, index) =>
                    Object.entries(service).map(([key, value]) =>
                      key === "Chairman" ? (
                        <>
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={true}
                            content={value}
                          />
                        </>
                      ) : (
                        " "
                      )
                    )
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <div id="Schedule" className="tab-pane active">
                  {Object.entries(contentsImage ? contentsImage : {}).map(
                    ([key, value]) =>
                      key === "Executive Director" ? (
                        <div className="">
                          <img
                            style={{ width: "100%", height: "350px" }}
                            src={`${BASE_URL}/media/${value}`}
                            alt=""
                          />
                        </div>
                      ) : (
                        " "
                      )
                  )}
                  {newContents.map((service, index) =>
                    Object.entries(service).map(([key, value]) =>
                      key === "Executive Director" ? (
                        <>
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={true}
                            content={value}
                          />
                        </>
                      ) : (
                        " "
                      )
                    )
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default EventTabs;
