import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import justForYou from "../../images/justforyou.jpeg";
import "./items.css";
import {
  BASE_URL,
  GET_CATEGORY_BY_ID,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_ITEMS_ALL_BY_CATEGORY,
} from "../../constant/constants";

var settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const OurItems = (props) => {
  const [contents, setContents] = useState([]);
  const [category, setCategory] = useState();
  const [contentsImage, setContentsImage] = useState("");

  const { id } = useParams();
  console.log("projecID", props.id);

  //for content images
  useEffect(() => {
    fetch(`${GET_ITEMS_ALL_BY_CATEGORY}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);

        console.log("imagesContent", data);
      })
      .catch(() => {});
    fetch(`${GET_CATEGORY_BY_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content
  useEffect(() => {
    fetch(`${GET_ITEMS_ALL_BY_CATEGORY}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content items
  // const newContents = items(contents);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  console.log(`newContents`, contents);
  console.log(`sajdhsakjhdlkahd`, `/${category?.name}/${id || props.id}`);
  return (
    <div style={{ display: contents?.length > 0 ? "block" : "none" }}>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-2 '>
            <Link
              to={`/${category?.name}/${category?.id}`}
              onClick={ClickHandler}
              data-toggle='tooltip'
              data-placement='bottom'
              title='Click to show all product'
            >
              <center>
                {" "}
                <img
                  src={`${BASE_URL}${category?.image}`}
                  alt='Your Image'
                  className='img-fluid  rounded-pill mb-2 shadow-lg p-3 mb-5 bg-body rounded'
                  style={{ width: "150px", height: "150px" }}
                />
              </center>
              <h5 className='text-center mb-5' style={{ color: "red" }}>
                {category?.name}
              </h5>
            </Link>
          </div>
          <div className='col-md-10'>
            <div
            // id='carouselExampleControls'
            // className='carousel slide'
            // data-bs-ride='carousel'
            >
              <div>
                <div className='carousel-item active'>
                  <div className='row'>
                    {contents?.slice(0, 8).map(
                      (item, tm) =>
                        item?.is_published && (
                          <div className='col-md-3 mb-4'>
                            <Link
                              to={`/productdetails/${item?.id}`}
                              onClick={ClickHandler}
                            >
                              <div
                                className='card h-100 border  rounded'
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  src={`${BASE_URL}${item.image}`}
                                  width='150'
                                  height='150'
                                  className='card-img-top'
                                  alt='Card Image'
                                />
                                <div
                                  className='card-body p-2 '
                                  style={{ backgroundColor: "#f4faff" }}
                                >
                                  <p
                                    className='card-title text-center pb-3 '
                                    style={{
                                      fontSize: "16px",
                                      overflow: "hidden",
                                      height: "54px",
                                    }}
                                  >
                                    {item?.name}
                                  </p>
                                  <div className='d-flex justify-content-center'>
                                    <div
                                      className='card-text justify-content-center bold font-weight-bold text-center'
                                      style={{
                                        color: "#ff7519",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <center>
                                        <b> {item?.price}</b>
                                      </center>
                                    </div>
                                  </div>
                                  <div className='d-flex justify-content-center'>
                                    <div
                                      className='card-text justify-content-center bold text-primary font-weight-bold text-center'
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    >
                                      <center>
                                        {" "}
                                        <b>Wholesale</b>
                                      </center>
                                    </div>
                                  </div>
                                  {/* <div className='d-flex justify-content-between'>
                                <div
                                  className='card-text text bold font-weight-bold'
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <b> Min Quantity: {item?.min_quantity}</b>
                                </div>
                              </div> */}
                                </div>
                              </div>
                            </Link>
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='prev'
              >
                <span
                  className='carousel-control-prev-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Previous</span>
              </button>
              <button
                className='carousel-control-next'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='next'
              >
                <span
                  className='carousel-control-next-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Next</span>
              </button>
            </div>
          </div>
          {/* <div>
            {" "}
            <Link
              to={`/${category?.name}/${id || props.id}`}
              onClick={ClickHandler}
            >
              <h5 style={{ color: "red", textAlign: "end", cursor: "pointer" }}>
                See More ...
              </h5>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurItems;
