import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  BASE_URL,
  GET_CATEGORY_BY_ID,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_ITEMS_ALL_BY_CATEGORY,
} from "../../constant/constants";
import { Interweave } from "interweave";
import PageTitle from "../pagetitle";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const FormalShirt = (props) => {
  const { id } = useParams();

  console.log("aboutID", props.id);
  const [contents, setContents] = useState([]);
  const [category, setCategory] = useState();

  const [contentsImage, setContentsImage] = useState("");

  useEffect(() => {
    fetch(`${GET_ITEMS_ALL_BY_CATEGORY}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data);
      })
      .catch(() => {});

    // Get Content Image
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);

        console.log("imagesContent", data);
      })
      .catch(() => {});
  }, [id, props.id]);
  console.log("contents", contents);

  // const newContents = items(contents);
  // console.log("content", newContents);
  const ClickHandler = () => {
    // window.scrollTo(10, 0);
  };

  useEffect(() => {
    fetch(`${GET_CATEGORY_BY_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
      })
      .catch(() => {});
  }, [id, props.id]);
  return (
    <>
      {" "}
      <PageTitle
        pageTitle={`${category?.name}`}
        pagesub={`${category?.name}`}
      />
      <div className='container mt-5'>
        <div className='row' style={{ justifyContent: "center" }}>
          <div className='col-md-10 '>
            <div
            // id='carouselExampleControls'
            // className='carousel slide'
            // data-bs-ride='carousel'
            >
              <div>
                <div className=''>
                  <div className='row'>
                    {contents.map((item, tm) => (
                      <div className='col-md-3 mb-4'>
                        <Link
                          to={`/productdetails/${item?.id}`}
                          onClick={ClickHandler}
                        >
                          <div
                            className='card h-100 border  rounded'
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src={`${BASE_URL}${item.image}`}
                              width='150'
                              height='150'
                              className='card-img-top'
                              alt='Card Image'
                            />
                            <div className='card-body p-2'>
                              <p
                                className='card-title text-center pb-3 '
                                style={{
                                  fontSize: "16px",
                                  overflow: "hidden",
                                  height: "54px",
                                }}
                              >
                                {item?.name}
                              </p>
                              <div className='d-flex justify-content-between'>
                                <div
                                  className='card-text bold font-weight-bold text-center'
                                  style={{
                                    color: "#ff7519",
                                    fontSize: "16px",
                                  }}
                                >
                                  <center>
                                    <b> Price: {item?.price}</b>
                                  </center>
                                </div>
                              </div>
                              <div className='d-flex justify-content-between'>
                                <div
                                  className='card-text bold text-primary font-weight-bold text-center'
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <center>
                                    {" "}
                                    <b> MOQ: {item?.min_quantity} pieces</b>
                                  </center>
                                </div>
                              </div>
                              {/* <div className='d-flex justify-content-between'>
                                <div
                                  className='card-text text bold font-weight-bold'
                                  style={{
                                    fontSize: "16px",
                                  }}
                                >
                                  <b> Min Quantity: {item?.min_quantity}</b>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='prev'
              >
                <span
                  className='carousel-control-prev-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Previous</span>
              </button>
              <button
                className='carousel-control-next'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='next'
              >
                <span
                  className='carousel-control-next-icon'
                  aria-hidden='true'
                ></span>
                <span className='visually-hidden'>Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormalShirt;
