import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_ITEMS_DETAILS_BY_ID,
  GET_ITEMS_IMAGES_BY_ID,
  GET_MENUS_ALL_NESTED,
} from "../../constant/constants";
import "./item.css";
import { Interweave } from "interweave";
import { Carousel } from "react-bootstrap-v5";
import PageTitle from "../pagetitle";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const ItemDetails = (props) => {
  const { id, companyName } = useParams();
  const [content, setContent] = useState("");
  const [contentImg, setContentImg] = useState([]);
  console.log(`contentImg`, contentImg);
  const [homeId, setHomeId] = useState();

  useEffect(() => {
    fetch(`${GET_ITEMS_DETAILS_BY_ID}${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContent(data);
      })
      .catch(() => {});
    fetch(`${GET_ITEMS_IMAGES_BY_ID}${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentImg(data?.item_images);
      })
      .catch(() => {});
  }, [id]);

  function change_image(image) {
    var container = document.getElementById("main_image");

    container.src = image.src;
  }

  document.addEventListener("DOMContentLoaded", function (event) {});

  return (
    <>
      <PageTitle pageTitle={`${content?.name}`} pagesub={`${content?.name}`} />

      <div className='container mt-5 mb-5'>
        <div className='row d-flex justify-content-center'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='row'>
                <div className='col-md-6'>
                  <Carousel fade>
                    {contentImg?.map((n) => (
                      <Carousel.Item>
                        <img
                          className='d-block w-100 mt-5'
                          // style={{ objectFit: "cover", opacity: "0.7" }}
                          style={{
                            filter: "brightness(100%)",
                            height:
                              window.innerWidth <= 767 ? "250px" : "400px",
                          }}
                          // height='400px'
                          width='300px'
                          src={`${BASE_URL}${n.images}`}
                          alt='First slide'
                        />

                        <Carousel.Caption>
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={false}
                            content={n.title}
                            className='mt-5'
                          />
                          <Interweave
                            allowAttributes
                            allowElements
                            disableLineBreaks={false}
                            content={n.subtitle}
                          />
                          <br />
                        </Carousel.Caption>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <div className='col-md-6'>
                  <div className='product p-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='d-flex align-items-center'> </div>{" "}
                      <i className='fa fa-shopping-cart text-muted'></i>
                    </div>
                    <div className='mt-4 mb-3'>
                      {" "}
                      <span className='text-uppercase text-muted brand'>
                        {content?.category?.name}
                      </span>
                      <h5 className='text-uppercase'>{content?.name}</h5>
                      <div className='price d-flex flex-row align-items-center'>
                        {" "}
                        <span className='act-price'>
                          Price:{content?.price}{" "}
                        </span>
                        <div className='ml-5' style={{ marginLeft: "20px" }}>
                          {" "}
                          <small className=''>
                            MOQ:{content?.min_quantity} pieces
                          </small>{" "}
                        </div>
                      </div>
                    </div>
                    <p className='about'>
                      {/* {content?.full_description} */}

                      <Interweave
                        allowAttributes
                        allowElements
                        disableLineBreaks={true}
                        content={content?.value}
                        className='text-justify'
                      />
                    </p>
                    {/* <div className="sizes mt-5">
                                <h6 className="text-uppercase">Size</h6> <label className="radio"> <input type="radio" name="size" value="S" checked> <span>S</span> </label> <label className="radio"> <input type="radio" name="size" value="M"> <span>M</span> </label> <label className="radio"> <input type="radio" name="size" value="L"> <span>L</span> </label> <label className="radio"> <input type="radio" name="size" value="XL"> <span>XL</span> </label> <label className="radio"> <input type="radio" name="size" value="XXL"> <span>XXL</span> </label>
                            </div> */}
                    {/* <div className='cart mt-4 align-items-center'>
                      {" "}
                      <button className='btn btn-danger text-uppercase mr-2 px-4'>
                        Add to cart
                      </button>{" "}
                      <i className='fa fa-heart text-muted'></i>{" "}
                      <i className='fa fa-share-alt text-muted'></i>{" "}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDetails;
