import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import ProjectSection from "../../components/ProjectSection";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";

const ProjectPage = () => {
  const [profileId, setProfileId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === "Project" ? setProfileId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);
  console.log("Project", profileId);
  return (
    <Fragment>
      <PageTitle pageTitle={"Project"} pagesub={"Project"} />
      <ProjectSection pbClass={"pb-0"} id={profileId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectPage;
