import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CREATE_MEMBER,
  GET_ADVISERS_WITHOUT_PG,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_MENUS_ALL_NESTED,
  GET_SITESETTINGS,
} from "../constant/constants";

//buildformdata
const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data === null ? "" : data;

    formData.append(parentKey, value);
  }
};

//convertJsonToFormData
const jsonToFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);
  return formData;
};

export const getAllmenus = () => (dispatch) => {
  fetch(GET_MENUS_ALL_NESTED)
    .then((res) => res.json())
    .then((data) => dispatch(setAllMenus(data.menus)));
};
export const getAlladvisers = () => (dispatch) => {
  fetch(GET_ADVISERS_WITHOUT_PG)
    .then((res) => res.json())
    .then((data) => dispatch(setAllAdvisers(data.adviser_members)));
};
export const getAllimages = (id) => (dispatch) => {
  fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setAllImages(data.content_images)));
};
export const getAllcontents = (id) => (dispatch) => {
  fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
    .then((res) => res.json())
    .then((data) => dispatch(setAllContents(data.menu_contents)));
};
export const getSiteSetting = () => (dispatch) => {
  fetch(GET_SITESETTINGS)
    .then((res) => res.json())
    .then((data) => dispatch(setSiteSetting(data.general_settings)));
};

export const saveMember = createAsyncThunk(
  "amsProjectManagement/data/saveMember",
  async (memberData, { dispatch, getState }) => {
    const memberDataToFormData = jsonToFormData(memberData);
    const authTOKEN = {
      headers: {
        // "Content-type": "multipart/form-data",
        // Authorization: localStorage.getItem("jwt_access_token"),
      },
    };
    const response = await axios.post(
      `${CREATE_MEMBER}`,
      memberDataToFormData,
      authTOKEN
    );
    return response;
  }
);

const dataSlice = createSlice({
  name: "amsProjectManagement/data",
  initialState: {
    menus: [],
    advisers: [],
    images: [],
    contents: [],
    siteSetting: [],
  },
  reducers: {
    setAllMenus: (state, action) => {
      state.menus = action.payload ? action.payload : [];
    },
    setAllAdvisers: (state, action) => {
      state.advisers = action.payload ? action.payload : [];
    },
    setAllImages: (state, action) => {
      state.images = action.payload ? action.payload : [];
    },
    setAllContents: (state, action) => {
      state.contents = action.payload ? action.payload : [];
    },
    setSiteSetting: (state, action) => {
      state.siteSetting = action.payload ? action.payload : [];
    },
  },
  extraReducers: {
    // [saveMember.fulfilled]: (state, action) => action.payload,
  },
});

const {
  setAllMenus,
  setSiteSetting,
  setAllImages,
  setAllContents,
  setAllAdvisers,
} = dataSlice.actions;
export default dataSlice.reducer;
