import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_CONTENTS_BY_MENU_ID,
  GET_SITESETTINGS,
} from "../../constant/constants";
import ContactForm from "../ContactFrom";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const Contactpage = (props) => {
  const { id } = useParams();
  const [contents, setContents] = useState("");

  console.log("menuID", id);

  //for get menu
  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("ContactContent", data);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content items
  const newContents = items(contents);
  console.log("content Contact", newContents);

  const [generalData, setGeneralData] = useState({});
  //get general setting data
  useEffect(() => {
    fetch(`${GET_SITESETTINGS}`)
      .then((response) => response.json())
      .then((data) => setGeneralData(data?.general_settings[0] || {}));
  }, []);

  const Map = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.8330960847343!2d90.39318231411096!3d23.71765358460549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8e05a65ac59%3A0xfa3ffc33cbfe0530!2sN%20STYLE!5e0!3m2!1sen!2sbd!4v1681030557452!5m2!1sen!2sbd" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"`;

  return (
    <section className='wpo-contact-pg-section section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col col-lg-10 offset-lg-1'>
            <div className='office-info'>
              <div className='row'>
                <div className='col col-xl-4 col-lg-6 col-md-6 col-12 office-info-item'>
                  <div className=''>
                    <div className='office-info-icon'>
                      <div className='icon'>
                        <i className='fi flaticon-placeholder'></i>
                      </div>
                    </div>
                    <div className='office-info-text'>
                      {generalData?.address}
                    </div>
                  </div>
                </div>
                <div className='col col-xl-4 col-lg-6 col-md-6 col-12 office-info-item'>
                  <div className=''>
                    <div className='office-info-icon'>
                      <div className='icon'>
                        <i className='fi flaticon-email'></i>
                      </div>
                    </div>
                    <div className='office-info-text'>{generalData?.email}</div>
                  </div>
                </div>
                <div className='col col-xl-4 col-lg-6 col-md-6 col-12 office-info-item'>
                  <div className=''>
                    <div className='office-info-icon'>
                      <div className='icon'>
                        <i className='fi flaticon-phone-call'></i>
                      </div>
                    </div>
                    <div className='office-info-text'>{generalData?.phone}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='wpo-contact-title'>
              <h2>Have Any Question?</h2>
              <p>
                It is a long established fact that a reader will be distracted
                content of a page when looking.
              </p>
            </div>
            <div className='wpo-contact-form-area' style={{ margin: "30px" }}>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <section className='wpo-contact-map-section'>
        <div className='wpo-contact-map'>
          <iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.8330960847343!2d90.39318231411096!3d23.71765358460549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8e05a65ac59%3A0xfa3ffc33cbfe0530!2sN%20STYLE!5e0!3m2!1sen!2sbd!4v1681030557452!5m2!1sen!2sbd'></iframe>{" "}
        </div>
      </section>
    </section>
  );
};

export default Contactpage;
