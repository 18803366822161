import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useParams } from "react-router-dom";
import countryCodes from "../../api/countrycodes";
import {
  BASE_URL,
  CREATE_DONATION,
  PAYMENT_MATHODS_BY_TYPE,
} from "../../constant/constants";

const DonatePage = () => {
  const { causeName, id } = useParams();
  const [data, setData] = useState({});
  const [paymentType, setPaymentType] = useState({ payment: "" });
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const history = useHistory();

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      member_id: localStorage.getItem("user_id"),
      country_code: selectedCountry.value,
      country: selectedCountry.label,
      cause: id,
    });
  };

  const updatePaymentType = (e) => {
    setPaymentType({
      [e.target.name]: e.target.value,
    });
  };

  const notify = () =>
    toast.success(`You are successfully payment for ${causeName}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });

  const SubmitHandler = (e) => {
    e.preventDefault();
    console.log(`data`, data);
    fetch(`${CREATE_DONATION}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        // authorization: `bearer ${localStorage.getItem("jwToken")}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        notify();

        setTimeout(() => history.push(`/cause-single/${id}`), 1000);
      });
  };

  //for content
  useEffect(() => {
    fetch(`${PAYMENT_MATHODS_BY_TYPE}${paymentType.payment}`)
      .then((response) => response.json())
      .then((data) => {
        setPaymentMethod(data.payment_method);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [paymentType.payment]);

  const handleCountryChange = (event) => {
    setSelectedCountry(
      countryCodes.find((country) => country.label === event.target.value)
    );
  };

  return (
    <Fragment>
      <PageTitle pageTitle={causeName} pagesub={"Donate"} />
      <div className="wpo-donation-page-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="wpo-donate-header">
                <h2>
                  Make a Donation for <br /> {causeName}
                </h2>
              </div>
              <div id="Donations" className="tab-pane">
                <form onSubmit={SubmitHandler}>
                  <div className="wpo-donations-amount">
                    <h2>Your Donation</h2>
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      id="amount"
                      onChange={updateData}
                      placeholder="Enter Donation Amount"
                    />
                  </div>
                  <div className="wpo-donations-details">
                    <h2>Details</h2>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="first_name"
                          onChange={updateData}
                          id="first_name"
                          value={localStorage.getItem("first_name")}
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          id="last_name"
                          onChange={updateData}
                          value={localStorage.getItem("last_name")}
                          placeholder="Last Name"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          onChange={updateData}
                          value={localStorage.getItem("user_email")}
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-lg-12 col-12 form-group d-flex">
                        <div className="col-md-4 col-12 col-sm-6 form-group">
                          <select
                            className="form-control"
                            style={{ padding: "12px" }}
                            id="country"
                            value={selectedCountry.label}
                            onChange={handleCountryChange}
                          >
                            {countryCodes.map((country) => (
                              <option key={country.label} value={country.label}>
                                {country.label} ({country.code})
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-2 col-sm-6 col-12 form-group">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="country_code"
                            id="country_code"
                            onChange={updateData}
                            value={selectedCountry.value}
                          />
                        </div>
                        <div className="col-md-6 col-12 col-sm-12 form-group">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            name="phone_number"
                            id="phone_number"
                            onChange={updateData}
                            value={localStorage.getItem("primary_phone")}
                            placeholder="1912345678"
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="Phone"
                          id="Phone"
                          onChange={updateData}
                          placeholder="Phone"
                          value={localStorage.getItem("primary_phone")}
                        />
                      </div> */}
                      <div className="col-lg-12 col-12 form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="address"
                          id="address"
                          onChange={updateData}
                          value={localStorage.getItem("street_address_one")}
                          placeholder="Address"
                        />
                      </div>

                      <div className="col-lg-12 col-12 form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          id="message"
                          onChange={updateData}
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="wpo-doanation-payment">
                    <h2>Choose Your Payment Method</h2>
                    <div className="wpo-payment-area">
                      <div className="row">
                        <div className="col-12">
                          <div className="wpo-payment-option" id="open4">
                            <div className="wpo-payment-select">
                              <ul>
                                <li className="addToggle">
                                  <input
                                    id="card"
                                    type="radio"
                                    name="payment"
                                    value="card"
                                    onChange={updatePaymentType}
                                  />
                                  <label htmlFor="card">Payment By Card</label>
                                </li>
                                <li className="addToggle">
                                  <input
                                    id="mobile"
                                    type="radio"
                                    name="payment"
                                    value="mobile"
                                    onChange={updatePaymentType}
                                  />
                                  <label htmlFor="mobile">
                                    Payment By Mobile Banking
                                  </label>
                                </li>
                                <li className="addToggle">
                                  <input
                                    id="offline"
                                    type="radio"
                                    name="payment"
                                    value="offline"
                                    onChange={updatePaymentType}
                                  />
                                  <label htmlFor="offline">
                                    Offline Payment
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <div id="open5" className="payment-name">
                              <ul>
                                {paymentMethod.map((paymentData, index) => (
                                  <li className="visa">
                                    <input
                                      id={paymentData.id}
                                      type="radio"
                                      name="payment_method"
                                      value={paymentData.id}
                                      onChange={updateData}
                                    />
                                    <label htmlFor={paymentData.id}>
                                      <img
                                        src={`${BASE_URL}${paymentData.image}`}
                                        alt=""
                                        height="40"
                                        width="78"
                                      />
                                    </label>
                                  </li>
                                ))}
                              </ul>
                              <div
                                className="contact-form form-style"
                                style={{
                                  display:
                                    paymentType?.payment === "card"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div className="row">
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <label>Card holder Name</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      name="card_holder"
                                      id="card_holder"
                                      onChange={updateData}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <label>Card Number</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      id="card_number"
                                      name="card_number"
                                      maxlength="16"
                                      onChange={updateData}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <label>CVV</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      name="cvc_code"
                                      id="cvc_code"
                                      maxlength="4"
                                      onChange={updateData}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-12 col-12">
                                    <label>Expire Date</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      name="expiry_date"
                                      id="expiry_date"
                                      onChange={updateData}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="contact-form form-style"
                                style={{
                                  display:
                                    paymentType?.payment === "mobile"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-12">
                                    <label>Payment Number</label>
                                    <input
                                      type="text"
                                      placeholder=""
                                      name="payment_number"
                                      id="payment_number"
                                      onChange={updateData}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="contact-form form-style"
                                style={{
                                  display:
                                    paymentType?.payment === "offline"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-12"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="submit-area">
                    <button type="submit" className="theme-btn submit-btn">
                      Donate Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default DonatePage;
