import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import FormalShirt from "../../components/FormalShirt";
import { useState } from "react";
import {
  GET_CATEGORYS_ALL,
  GET_CATEGORY_BY_ID,
} from "../../constant/constants";
import { useEffect } from "react";

const FormalShirtPage = () => {
  const [categoryId, setCategoryId] = useState();

  const [category, setCategory] = useState();

  useEffect(() => {
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Formal Full Shirt" ? setCategoryId(e.id) : null
        );
      });
  }, []);

  useEffect(() => {
    fetch(`${GET_CATEGORY_BY_ID}${categoryId}`)
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
      })
      .catch(() => {});
  }, [categoryId]);

  return (
    <Fragment>
      {/* <PageTitle
        pageTitle={`${category?.name}`}
        pagesub={`${category?.name}`}
      /> */}
      <FormalShirt id={categoryId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default FormalShirtPage;
