import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import History from "../../components/History";

const HistoryPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={"History"} pagesub={"History"} />
      <History />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HistoryPage;
