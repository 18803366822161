import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import { GET_MENUS_ALL_NESTED } from "../../constant/constants";
import Advisor from "../../components/Advisor";

const AdvisorPage = () => {
  const [profileId, setProfileId] = useState();

  useEffect(() => {
    fetch(`${GET_MENUS_ALL_NESTED}`)
      .then((response) => response.json())
      .then((data) => {
        data.menus.find((e) =>
          e.name === "Advisor" ? setProfileId(e.id) : null
        );
      })
      .catch(() => {});
  }, []);
  console.log("Advisor", profileId);

  return (
    <Fragment>
      <PageTitle
        pageTitle={"Our Advisor Committee"}
        pagesub={"Advisor Committee"}
      />
      <Advisor id={profileId} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AdvisorPage;
