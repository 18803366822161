import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../components/pagetitle";
import { Interweave } from "interweave";
import { Carousel } from "react-bootstrap-v5";
import Scrollbar from "../../components/scrollbar";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer";
import {
  BASE_URL,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_MENU_ITEM_BY_ID,
} from "../../constant/constants";

function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}

const LiveProgramSinglePage = (props) => {
  console.log(`props`, props);

  const { id } = useParams();
  const [contents, setContents] = useState("");
  const [contentsImage, setContentsImage] = useState("");
  const [menuContent, setMenuContent] = useState({});

  useEffect(() => {
    fetch(`${GET_CONTENTS_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data.menu_contents);

        console.log("allmenucontent", data);
      })
      .catch(() => {});

    fetch(`${GET_MENU_ITEM_BY_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setMenuContent(data);

        console.log("allmenucontent", data);
      })
      .catch(() => {});
    // Get Content Image
    fetch(`${GET_IMAGE_BY_MENU_ID}/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);

        console.log("imagesContent", data);
      })
      .catch(() => {});
  }, [id]);

  const newContents = items(contents);
  console.log("contents,contentsImage", newContents, contentsImage);
  console.log("menuContent", menuContent);

  return (
    <Fragment>
      {/* <Navbar2 Logo={menuContent?.image} /> */}
      <PageTitle pageTitle={menuContent?.name} pagesub={menuContent?.name} />
      <section className="wpo-project-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-content">
                  <div className="container-fluid">
                    {Object.entries(contentsImage ? contentsImage : {}).map(
                      ([key, value]) =>
                        key === "Program Image Head" ? (
                          <img
                            style={{
                              borderRadius: "2%",
                              display: "block",
                              maxHeight: "fit-content",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                            className="d-block w-100 mx-auto text-center rounded"
                            src={`${BASE_URL}/media/${value}`}
                            alt=""
                          />
                        ) : (
                          " "
                        )
                    )}
                  </div>
                  <div className="wpo-project-single-content-des">
                    <div className="wpo-project-single-content-des-wrap">
                      <div className="wpo-project-single-content-des-left">
                        <h2>{menuContent?.name}</h2>
                        <Interweave
                          allowAttributes
                          allowElements
                          disableLineBreaks={true}
                          content={menuContent?.note}
                        />
                      </div>
                      <div className="wpo-project-single-content-des-right">
                        {newContents.map((service, index) =>
                          Object.entries(service).map(
                            ([contentKey, contentValue]) =>
                              contentKey === "Duration" && (
                                <div className=" col-12" key={index}>
                                  <h2>{contentKey}</h2>

                                  <Interweave
                                    allowAttributes
                                    allowElements
                                    disableLineBreaks={true}
                                    content={contentValue}
                                  />
                                </div>
                              )
                          )
                        )}
                      </div>
                    </div>

                    <div>
                      <Carousel fade>
                        {Object.entries(contentsImage ? contentsImage : {}).map(
                          ([key, value]) =>
                            key === "Program Image" &&
                            value.map((img) => (
                              <Carousel.Item>
                                <img
                                  className="d-block w-100 mx-auto text-center rounded"
                                  // style={{ objectFit: "cover", opacity: "0.7" }}
                                  // style={{ filter: "brightness(70%)" }}
                                  height={400}
                                  src={`${BASE_URL}/media/${img}`}
                                  alt="First slide"
                                />
                              </Carousel.Item>
                            ))
                        )}
                      </Carousel>
                    </div>
                  </div>
                </div>
                <div className="wpo-solutions-section">
                  {/* <h2>Best Benefits of Project</h2> */}
                  <div className="row">
                    {/* {Services.slice(0, 3).map((service, Sitem) => (
                      <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
                        <div className="wpo-solutions-item">
                          <div className="wpo-solutions-icon">
                            <div className="icon">
                              <i className={`fi ${service.fIcon1}`}></i>
                            </div>
                          </div>
                          <div className="wpo-solutions-text">
                            <h2>
                              <Link
                                onClick={ClickHandler}
                                to={`/service-single/${service.id}`}
                              >
                                {service.title}
                              </Link>
                            </h2>
                            <p>
                              The lower-numbered purposes are better understood
                              and practiced{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))} */}

                    {newContents.map((service, index) =>
                      Object.entries(service).map(
                        ([contentKey, contentValue]) =>
                          contentKey === "Program Benefits" && (
                            <div className=" col-12" key={index}>
                              <h2>{contentKey}</h2>

                              <Interweave
                                allowAttributes
                                allowElements
                                disableLineBreaks={true}
                                content={contentValue}
                              />
                            </div>
                          )
                      )
                    )}
                  </div>
                </div>
                {/* <div className="tag-share clearfix">
                  <div className="tag">
                    <ul>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          Charity
                        </Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          Wildlife
                        </Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          Nature
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="share">
                    <span>Share: </span>
                    <ul>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          <i className="ti-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          <i className="ti-twitter-alt"></i>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/project-single/1">
                          <i className="ti-instagram"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="more-posts">
                  <div className="previous-post">
                    <Link onClick={ClickHandler} to="/project-single/1">
                      <div className="post-img">
                        <img src={ProjectSingle.prImg1} alt="" />
                      </div>
                      <div className="post-text">
                        <span className="post-control-link">Previous Post</span>
                        <span className="post-name">Child Education</span>
                      </div>
                    </Link>
                  </div>
                  <div className="next-post">
                    <Link onClick={ClickHandler} to="/project-single/2">
                      <div className="post-text">
                        <span className="post-control-link">Next Post</span>
                        <span className="post-name">Healthy Food</span>
                      </div>
                      <div className="post-img">
                        <img src={ProjectSingle.prImg2} alt="" />
                      </div>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default LiveProgramSinglePage;
