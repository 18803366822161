import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  BASE_URL,
  GET_LIFEMEMBERS_WITHOUT_PAGINATION,
} from "../../constant/constants";

const Executive = (props) => {
  const [lifemembers, setLifemembers] = useState([]);

  const { id } = useParams();
  console.log("projecID", id);

  //for content images
  useEffect(() => {
    fetch(`${GET_LIFEMEMBERS_WITHOUT_PAGINATION}`)
      .then((response) => response.json())
      .then((data) => {
        setLifemembers(data.life_members);
        console.log("executive_members", data.life_members);
      })
      .catch(() => {});
  }, []);

  return (
    <section
      className={`${props.Fclass} section-padding  ${props.vclassClass}`}
    >
      <div className="container">
        <div className="row">
          {lifemembers?.map((lifemember, sitem) => (
            <div
              className="col col-xl-4 col-lg-6 col-sm-6 col-12  "
              key={sitem}
            >
              <div
                className="widget about-widget shadow p-3 mb-5 bg-gray   p-4"
                style={{ borderRadius: "50px" }}
              >
                <div className="img-holder justify-content-center d-flex">
                  <img
                    src={`${BASE_URL}${lifemember?.member?.image}`}
                    alt={lifemember.first_name}
                    className=" rounded-circle mb-3 text-center shadow-lg p-1 mb-5 bg-white rounded"
                    width="200"
                    height="200"
                  />
                </div>
                <h2 className="text-center">
                  {" "}
                  {lifemember?.member?.first_name}{" "}
                  {lifemember?.member?.last_name}
                </h2>
                <h5 className="text-center"> {lifemember?.designation}</h5>
                <p>Contact : {lifemember?.member?.primary_phone} </p>
                <p>Profession : {lifemember?.member?.profession} </p>
                <p>Address : {lifemember?.member?.street_address_one} </p>
                <div className="social"></div>
                <div className="aw-shape"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Executive;
