import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import Background from "../../components/Background";

const BackgroundPage = () => {
  return (
    <Fragment>
      <PageTitle pageTitle={"Background"} pagesub={"Background"} />
      <Background />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BackgroundPage;
