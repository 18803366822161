import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import ItemDetails from "../../components/ItemDetails";

const ItemPage = () => {
  return (
    <Fragment>
      {/* <PageTitle pageTitle={"Item Details"} pagesub={"Item Details"} /> */}
      <ItemDetails />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ItemPage;
