import { Interweave } from "interweave";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import justForYou from "../../images/justforyou.jpeg";
import "./items.css";
import {
  BASE_URL,
  GET_CATEGORYS_ALL,
  GET_CATEGORYS_ALL_NESTED,
  GET_CATEGORY_BY_ID,
  GET_CONTENTS_BY_MENU_ID,
  GET_IMAGE_BY_MENU_ID,
  GET_ITEMS_ALL_BY_CATEGORY,
} from "../../constant/constants";

var settings = {
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function items(obj) {
  let content = [];

  // eslint-disable-next-line no-unused-vars
  for (let key in obj) {
    let contentItem = {};
    let objs = obj;

    contentItem[`${key}`] = objs[key];
    content.push(contentItem);
  }
  return content;
}
const OurCategorys = (props) => {
  const [contents, setContents] = useState([]);
  const [category, setCategory] = useState();
  const [contentsImage, setContentsImage] = useState("");
  const [fhsId, setFhsId] = useState();
  const [ffsId, setFfsId] = useState();
  const [chsId, setChsId] = useState();
  const [cfsId, setCfsId] = useState();
  const [mfp, setMfpId] = useState();
  const [germents, setGermentsId] = useState();
  const [allCategories, setAllCAtegories] = useState();
  const { id } = useParams();
  console.log("projecID", props.id);
  useEffect(() => {
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        setAllCAtegories(data?.categories);
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Formal Full Shirt" ? setFfsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Formal Half Shirt" ? setFhsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Casual Half Shirt" ? setChsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Casual Full Shirt" ? setCfsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Men for Panjabi" ? setMfpId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Garments" ? setGermentsId(e.id) : null
        );
      });
  }, []);

  //for content images
  useEffect(() => {
    fetch(`${GET_ITEMS_ALL_BY_CATEGORY}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContentsImage(data.content_images);

        console.log("imagesContent", data);
      })
      .catch(() => {});
    fetch(`${GET_CATEGORY_BY_ID}${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setCategory(data);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content
  useEffect(() => {
    fetch(`${GET_ITEMS_ALL_BY_CATEGORY}/${id || props.id}`)
      .then((response) => response.json())
      .then((data) => {
        setContents(data);

        console.log("allmenucontent", data.menu_contents);
      })
      .catch(() => {});
  }, [id, props.id]);

  //for content items
  // const newContents = items(contents);
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  console.log(`newContents`, contents);
  console.log(`sajdhsakjhdlkahd`, `/${category?.name}/${id || props.id}`);
  return (
    <div style={{ display: allCategories?.length > 0 ? "block" : "none" }}>
      <div className='container mt-5'>
        <div className='row justify-content-center mb-5'>
          <div className='col-lg-6'>
            <div className='wpo-section-title'>
              <span>Our Top</span>
              <h2> Categories</h2>
            </div>
          </div>
        </div>

        <div className='row justify-content-center '>
          {allCategories?.map(
            (category, tm) =>
              (category?.name == "Formal Full Shirt" ||
                category?.name == "Formal Half Shirt" ||
                category?.name == "Casual Half Shirt" ||
                category?.name == "Casual Full Shirt" ||
                category?.name == "Men for Panjabi" ||
                category?.name == "Garments") && (
                <div className='col-md-2 '>
                  <Link
                    to={`/${category?.name}/${category?.id}`}
                    onClick={ClickHandler}
                    data-toggle='tooltip'
                    data-placement='bottom'
                    title='Click to show all product'
                  >
                    <center>
                      {" "}
                      <img
                        src={`${BASE_URL}${category?.image}`}
                        alt='Your Image'
                        className='img-fluid categoryimg rounded-pill mb-3 shadow-lg p-3 bg-body rounded'
                        style={{ width: "150px", height: "150px" }}
                      />
                    </center>
                    <h5 className='text-center' style={{ color: "red" }}>
                      {category?.name}
                    </h5>
                  </Link>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default OurCategorys;
