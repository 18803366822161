import React, { Fragment, useEffect, useState } from "react";
import Hero from "../../components/hero";

import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import PartnerSection from "../../components/PartnerSection";
import AboutS2 from "../../components/AboutS2";
import OurLivePrograms from "../../components/OurLivePrograms";
import OurPreviousPrograms from "../../components/OurLivePrograms copy";
import OurBlogs from "../../components/OurBlogs";
import { useDispatch, useSelector } from "react-redux";
import { getAllmenus } from "../../dataSlice/dataSlice";
import {
  GET_CATEGORYS_ALL,
  GET_CATEGORYS_ALL_NESTED,
  GET_MENUS_ALL_NESTED,
} from "../../constant/constants";
import OurItems from "../../components/OurItems";
import OurIDemandableItems from "../../components/OurIDemandableItems";
import OurCategorys from "../../components/OurCategorys";

const HomePage = () => {
  const [fhsId, setFhsId] = useState();
  const [ffsId, setFfsId] = useState();
  const [chsId, setChsId] = useState();
  const [cfsId, setCfsId] = useState();
  const [mfp, setMfpId] = useState();
  const [germents, setGermentsId] = useState();
  console.log(`mfp`, mfp);
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.data.menus);

  useEffect(() => {
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Formal Full Shirt" ? setFfsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Formal Half Shirt" ? setFhsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Casual Half Shirt" ? setChsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Casual Full Shirt" ? setCfsId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Men for Panjabi" ? setMfpId(e.id) : null
        );
      });
    fetch(`${GET_CATEGORYS_ALL}`)
      .then((response) => response.json())
      .then((data) => {
        data.categories.find((e) =>
          e.name === "Garments" ? setGermentsId(e.id) : null
        );
      });
  }, []);

  console.log(`chsId,cfsId,fhsId,ffsId`, chsId, cfsId, fhsId, ffsId);

  return (
    <Fragment>
      <Hero />
      <OurCategorys tClass={"wpo-testimonial-area-s3 "} />

      <div
        style={{ visibility: ffsId ? "visible" : "hidden" }}
        className='row justify-content-center mt-5 mb-5'
      >
        <div className='col-lg-6'>
          <div className='wpo-section-title'>
            <span>Our </span>
            <h2> Products</h2>
          </div>
        </div>
      </div>

      <OurItems id={fhsId} tClass={"wpo-testimonial-area-s3 "} />
      <OurItems id={ffsId} tClass={"wpo-testimonial-area-s3 "} />
      <OurItems id={chsId} tClass={"wpo-testimonial-area-s3 "} />
      <OurItems id={cfsId} tClass={"wpo-testimonial-area-s3 "} />
      <OurItems id={mfp} tClass={"wpo-testimonial-area-s3 "} />
      <OurItems id={germents} tClass={"wpo-testimonial-area-s3 "} />

      <OurIDemandableItems tClass={"wpo-testimonial-area-s3 "} />
      {/* <OurLivePrograms tClass={"wpo-testimonial-area-s3 "} />

      <AboutS2 id={homeId} /> */}
      {/* <OurPreviousPrograms tClass={"wpo-testimonial-area-s3 "} /> */}
      {/* <Service5 id={serviceId} /> */}
      {/* <ProjectSection4 id={homeId} /> */}
      {/* <PartnerSection />
      <OurBlogs id={homeId} tClass={"wpo-testimonial-area-s3 "} /> */}

      <Scrollbar />
      <Footer />
    </Fragment>
  );
};
export default HomePage;
