import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BASE_URL } from "../../constant/constants";
import { getAlladvisers } from "../../dataSlice/dataSlice";

const Advisor = (props) => {
  const dispatch = useDispatch();
  const advisers = useSelector((state) => state.data.advisers);
  // GET_ADVISERS_WITHOUT_PG
  useEffect(() => {
    dispatch(getAlladvisers());
  }, [dispatch, props.id]);

  return (
    <section
      className={`${props.Fclass} section-padding  ${props.vclassClass}`}
    >
      <div className="container">
        <div className="row">
          {advisers?.map((advisorMember, sitem) => (
            <div
              className="col col-xl-4 col-lg-6 col-sm-6 col-12  "
              key={sitem}
            >
              <div
                className="widget about-widget shadow p-3 mb-5 bg-gray   p-4"
                style={{ borderRadius: "50px" }}
              >
                <div className="img-holder justify-content-center d-flex">
                  <img
                    src={`${BASE_URL}${advisorMember?.member?.image}`}
                    alt={advisorMember.first_name}
                    className=" rounded-circle mb-3 text-center shadow-lg p-1 mb-5 bg-white rounded"
                    width="200"
                    height="200"
                  />
                </div>
                <h2 className="text-center">
                  {" "}
                  {advisorMember?.member?.first_name}{" "}
                  {advisorMember?.member?.last_name}
                </h2>
                <h5 className="text-center"> {advisorMember?.designation}</h5>
                <p>Contact : {advisorMember?.member?.primary_phone} </p>
                <p>Profession : {advisorMember?.member?.profession} </p>
                <p>Address : {advisorMember?.member?.street_address_one} </p>
                <div className="social"></div>
                <div className="aw-shape"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Advisor;
